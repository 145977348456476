<template>
    <div class="view members">
        <section class="hero is-dark is-bold">
            <div class="bg-filter"></div>
            <div class="hero-body">
                <div class="container">
                    <h1 class="title">
                        We have <strong>{{ customers.length }}</strong> users, <strong>{{ verified.length }}</strong> of which are verified.
                    </h1>
                    <!-- <h2 class="subtitle">
                        subtitle
                    </h2> -->
                </div>
            </div>
            <div class="hero-foot">
                <nav class="tabs is-boxed is-fullwidth">
                    <div class="container">
                        <ul>
                            <li v-bind:class="{ 'is-active': $route.name === 'user-list' }">
                                <router-link to="/user">
                                    <span class="icon is-small">
                                        <font-awesome-icon icon="user"></font-awesome-icon>
                                    </span>
                                    <span>
                                        Users
                                    </span>
                                </router-link>
                            </li>
                            <li v-bind:class="{ 'is-active': $route.path === '/user/category' }">
                                <router-link to="/user/category">
                                    <span class="icon is-small">
                                        <font-awesome-icon icon="user-tag"></font-awesome-icon>
                                    </span>
                                    <span>
                                        User Category
                                    </span>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </section>
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: 'Members',
    components: {},
    data() {
        return {
            filterRole: 'customer'
        }
    },
    computed: {
        customers () {
            return this.$store.getters["users/items"];
        },
        verified () {
            return this.customers.filter((user) => user.verified)
        }
    },
    methods: {}
}
</script>

<style lang="scss" scoped>
.hero {
    background-image: url(~@/assets/hero_background_2.png) !important;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    & > .hero-body {
        & > .container {
            width: 100%;
        }
    }

    & > .hero-foot {
        .tabs {
            ul {
                margin-left: 10px;
                margin-right: 10px;

                li {
                    &.is-active {
                        color: #363636 !important;
                    }
                }
            }
        }
    }
}
</style>
